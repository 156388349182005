export function mouseMoveListener (evt) {
  const delta = evt.pageY - this.prevY
  if (this.isDragging) {
    const ratio = Math.abs(this.maxScrollPos / this.trackHeight)
    this.scrollPos += -delta * ratio

    if (this.scrollPos > 0) {
      this.scrollPos = 0
    }

    if (this.scrollPos < this.maxScrollPos) {
      this.scrollPos = this.maxScrollPos
    }
  }

  this.prevY = evt.pageY
}

export function mouseUpListener (/* evt */) {
  this.isDragging = false
}

export function wheelListener (evt) {
  if (this.listMouseOver) {
    evt.preventDefault()

    this.scroll(evt.wheelDeltaY)
  }
}
