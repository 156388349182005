<template>
  <div class="list d-flex flex-row border-left-light"
       :class="{ 'scroll-min': scrollPos === 0, 'scroll-max': maxScrollPos !== 0 && scrollPos ===
        maxScrollPos, 'is-touch': this.isTouch }">
    <div ref="inner" class="list-inner">
      <div ref="header" class="list-header">
        <ListItem class="d-flex align-items-center flex-row" :is-map="isMap">
          <span class="font-color-light text-uppercase">
            {{ $t('modules.SelenaMap.availableItems') }}
          </span>

          <span class="items-count px-2">{{ items.length }}</span>
        </ListItem>
      </div>

      <div ref="content" class="list-content" @mouseover="listMouseOver = true" @mouseleave="listMouseOver = false">
        <div ref="scroll" class="list-content-scrollable">
          <ListItem ref="listItem"
                    v-for="(item, i) in items"
                    :key="i"
                    :is-map="isMap"
                    :item="item"
                    :selected="selectedItem !== null && selectedItem.id === item.id"
                    @click="$emit('click:item', item)"
          />
        </div>
      </div>

      <div ref="footer" class="list-footer">
        <ListItem alternate class="d-flex justify-content-center" :is-map="isMap">
          <b-btn primary @click="$emit('click:all')">
            {{ $t('modules.SelenaMap.seeAll') }}
          </b-btn>
        </ListItem>
      </div>
    </div>

    <div ref="scrollContent" class="list-scroll border-left-light border-bottom-light"
         :class="{ 'd-none': this.isTouch }">
      <div ref="track" class="scroll-track">
        <div ref="trackbar" class="scroll-bar" :style="{ 'height': `${trackBarHeight}px` }"
             @mousedown="scrollBarDown"/>
      </div>
    </div>
  </div>
</template>

<script>
import {
  mouseUpListener,
  wheelListener,
  mouseMoveListener
} from '../helpers/listeners'

import BBtn from '../shared/b-btn'
import ListItem from './list-item'

export default {
  name: 'List',
  components: {
    BBtn, ListItem
  },
  props: {
    items: {
      type: Array,
      required: true
    },
    isMap: {
      type: Boolean,
      required: true,
      default: false
    },
    selectedItem: {
      required: false,
      default: null,
      validator: (v) => typeof v === 'object'
    }
  },
  data () {
    return {
      containerEnd: 0,
      isDragging: false,
      listeners: {
        mousemove: mouseMoveListener.bind(this),
        mouseup: mouseUpListener.bind(this),
        wheel: wheelListener.bind(this)
      },
      listMouseOver: false,
      maxScrollPos: 0,
      prevY: 0,
      scrollPos: 0,
      trackBarHeight: 0,
      trackHeight: 0
    }
  },
  mounted () {
    let h = 1024
    let top = 0
    let bottom = 0
    if (!this.isServer) {
      if (this.$refs.inner) {
        h = this.$refs.inner.getBoundingClientRect().height
        top = this.$refs.header.getBoundingClientRect().height
      }
      if (this.$refs.footer) {
        bottom = this.$refs.footer.getBoundingClientRect().height + 20
      }
    }
    this.containerEnd = h - top - bottom

    this.$refs.content.style.height = `${h}px`
    this.$refs.content.style.paddingTop = `${top}px`
    this.$refs.content.style.paddingBottom = `${bottom}px`
    this.$refs.scrollContent.style.height = `${h - bottom + 1}px`

    if (this.isTouch) {
      if (this.$refs.inner) {
        this.$refs.inner.style.overflow = 'auto'
        this.$refs.inner.style.overflowY = 'scroll'
      }
      if (this.$refs.scroll) {
        this.$refs.scroll.style.paddingBottom = `${bottom}px`
      }
      setTimeout(() => {
        if (this.$refs.listItem) {
          this.$refs.listItem.forEach((item) => {
            item.setVisibility(true)
          })
        }
      }, 1000)
    } else {
      if (!this.isServer) {
        if (this.$refs.track) {
          this.trackHeight = this.$refs.track.getBoundingClientRect().height
        }
      }
      if (window) {
        for (const key in this.listeners) {
          if (`on${key}` in window) {
            document.addEventListener(key, this.listeners[key], { capture: false, passive: false })
          }
        }
      }
    }
  },

  computed: {
    isTouch () {
      if (!this.isServer) {
        return 'ontouchstart' in window
      }
      return false
    },
    isServer () {
      return typeof window === 'undefined'
    },
    isApp () {
      if (!this.isServer) {
        return window.innerWidth < 768
      }
      return false
    }
  },
  destroyed () {
    if (!this.isTouch) {
      if (window) {
        for (const key in this.listeners) {
          if (`on${key}` in window) {
            document.removeEventListener(key, this.listeners[key])
          }
        }
      }
    }
  },
  watch: {
    items: {
      immediate: true,
      handler () {
        setTimeout(() => {
          const rH = this.containerEnd
          let iH = 0
          if (!this.isServer && this.$refs.scroll) {
            iH = this.$refs.scroll.getBoundingClientRect().height
          }
          this.maxScrollPos = -(iH - rH - 1)

          this.trackBarHeight = Math.min(
            this.trackHeight,
            Math.abs(rH / this.maxScrollPos) * this.trackHeight
          )
        }, 250)

        this.scrollPos = 0
      }
    },
    scrollPos (scrollTop) {
      if (this.isTouch) {
        return
      }

      if (this.maxScrollPos >= -this.containerEnd) {
        return
      }

      this.$refs.scroll.style.transform = `translateY(${scrollTop}px)`

      const ratio = Math.abs(scrollTop / this.maxScrollPos)
      const max = this.trackHeight - this.trackBarHeight

      this.$refs.trackbar.style.transform = `translateY(${ratio * max}px)`

      let distanceFromTop = 0
      scrollTop = -scrollTop
      this.$refs.listItem.forEach((item) => {
        item.setVisibility(
          distanceFromTop >= scrollTop && (distanceFromTop + item.height) - scrollTop - 1 <= this.containerEnd
        )

        distanceFromTop += item.height
      })
    },
    selectedItem (val) {
      let pos = 0

      if (val !== null) {
        const index = this.items.findIndex((item) => {
          return item.id === val.id
        })

        for (let i = 0; i < index; i++) {
          pos += this.$refs.listItem[i].height
        }

        if (-this.maxScrollPos <= pos) {
          pos = -this.maxScrollPos
        }
      }

      setTimeout(() => {
        this.scrollPos = -pos
      }, 250)
    }
  },
  methods: {
    scrollBarDown () {
      this.isDragging = true
    },
    scroll (delta) {
      delta = Math.floor(delta * 0.5)
      this.scrollPos += delta

      if (this.scrollPos > 0) {
        this.scrollPos = 0
      }

      if (this.scrollPos < this.maxScrollPos) {
        this.scrollPos = this.maxScrollPos
      }
    }
  }
}
</script>
