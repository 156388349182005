<template>
  <div id="fake-select-wrap">
    <div class="fake-select-backdrop" :class="{ 'open': isOpen, 'is-compact': compact }"
         @click="toggle"/>
    <div ref="select" class="fake-select scrollFix"
         :class="{ 'open': isOpen, 'is-compact': compact, 'disabled': disabled }" v-on="$listeners">
      <div class="fake-select-title" @click="toggle">
        <div v-if="centered" class="spacer"/>
        <span class="text">
          {{ title }}
        </span>
        <span class="icon">
          <img :src="this.icon('caret-down')">
        </span>
      </div>
      <div ref="list" class="fake-select-options">
        <div v-if="reset" class="fake-select-option fake-select-option-reset"
             :class="{ 'active': selected === null }" @click="resetSelected()">
          {{ reset }}
        </div>
        <slot>
          <div v-for="(opt, i) in options" :key="i"
               :class="{ 'active': checkIsOptionSelected(opt) }" class="fake-select-option"
               @click="toggleOption(opt)">
            {{ opt.name }} {{ opt.city ? `- ${opt.city}` : null }}
            <template v-if="opt.street">
              {{ isDuplicatedItem(opt) ? `- ${opt.street}` : null }}
            </template>
          </div>
        </slot>
      </div>
    </div>
    <div v-if="errors.length" class="select-errors">
      <div v-for="(error, i) in errors" :key="i" class="pl-3 invalid-feedback d-block">
        {{ error }}
      </div>
    </div>
  </div>
</template>

<script>
import IconService from '../services/icon-service'

export default {
  name: 'FakeSelect',
  props: {
    button: {
      type: Boolean,
      required: false,
      default: false
    },
    centered: {
      type: Boolean,
      required: false,
      default: false
    },
    compact: {
      type: Boolean,
      required: false,
      default: false
    },
    multi: {
      type: Boolean,
      required: false,
      default: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    errors: {
      type: Array,
      required: false,
      default: () => []
    },
    model: {
      required: false,
      validator: (v) => typeof v === 'object',
      default: null
    },
    options: {
      type: Array,
      required: false,
      default: null
    },
    placeholder: {
      type: String,
      required: true
    },
    reset: {
      type: String,
      required: false,
      default: null
    }
  },
  data () {
    return {
      isOpen: false,
      filteredItems: []
    }
  },
  computed: {
    title () {
      if (this.multi) {
        return this.selected.length
          ? this.selected.map(item => item.name).join(', ')
          : this.placeholder
      }
      return this.selected
        ? this.selected.name + (this.selected.city
          ? ` - ${this.selected.city}`
          : '')
        : this.placeholder
    },
    selected: {
      get () {
        return this.model
      },
      set (val) {
        this.isOpen = false
        this.$emit('update:model', val)
        this.$emit('change', val)
      }
    }
  },
  methods: {
    checkIsOptionSelected (opt) {
      if (this.multi) {
        const index = this.selected.findIndex(item => item.id === opt.id)
        return index >= 0
      }
      return this.selected && this.selected.id === opt.id
    },
    toggleOption (opt) {
      if (this.multi) {
        const index = this.selected.findIndex(item => {
          return opt.id === item.id
        })
        if (index >= 0) {
          this.selected.splice(index, 1)
        } else {
          this.selected.push(opt)
        }
      } else {
        this.selected = opt
      }
    },
    resetSelected () {
      if (this.multi) {
        this.selected = []
        return
      }
      this.selected = null
    },
    icon (icon) {
      return IconService.getInstance().resolver(icon)
    },
    toggle () {
      if (this.disabled || this.button) {
        return
      }

      this.isOpen = !this.isOpen

      if (this.options[0] && this.options[0].street) {
        const itemsArr = this.options.map(item => item.name)

        this.filteredItems = itemsArr.filter(function (item, idx) {
          return itemsArr.indexOf(item) !== idx
        })
      }

      if (this.isOpen) {
        let rect = { height: 1024, top: 0, bottom: 0 }
        if (!this.isServer) {
          rect = this.$el.getBoundingClientRect()
        }
        let h = 1024
        if (window) {
          h = window.innerHeight - rect.height - rect.top - 15
        }
        this.$refs.list.style.maxHeight = `${h}px`
      }
    },
    isDuplicatedItem (item) {
      return this.filteredItems.some(itemName => itemName === item.name)
    }
  }
}
</script>
