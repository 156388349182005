import { PoiMapConfig } from './PoiMap.contracts'

export const POI_MAP_KEY = 'PoiMapComponent'

export const POI_MAP_CONFIG: PoiMapConfig = {
  configurableLocales: {
    HU: {
      displayRegionSelect: false
    }
  }
}
