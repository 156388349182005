<template>
  <div class="details-modal-backdrop" :class="{ 'show': show, 'is-app': isApp }" @click="onBackdropClick">
    <div v-if="item" class="details-modal" :class="{ 'is-app': isApp }" @click="onModalClick">
      <div class="row">
        <div class="col-12 col-md-8">
          <div class="line font_bold">
            {{ item.city }}
          </div>

          <div class="name">
            {{ item.name }}
          </div>
        </div>

        <div class="col-12 d-md-none">
          <hr />
        </div>

        <template v-if="isApp">
          <div class="col-12">
            <p class="line font_bold mb-3">{{ $t('modules.SelenaMap.details') }}</p>

            <div class="line">
              {{ getComposedStreet(item.street_type) }} {{ item.street }}
            </div>

            <div class="line font-weight-normal">
              {{ $t('modules.SelenaMap.phone') }}

              <a v-for="phone in item.phones" :href="'tel:'+phone" :key="phone" class="notification font-weight-normal">
                {{ phone }}
              </a>
            </div>

            <div class="line">
              <a :href="'mailto:'+item.email" class="notification">{{ item.email }}</a>
            </div>
          </div>
        </template>

        <div class="col d-md-none">
          <hr />
        </div>

        <div class="opened-wrap mt-md-0 col-12 col-md-4">
          <p class="line font_bold mb-3">{{ $t('modules.SelenaMap.hoursOpen') }}</p>

          <div class="opened">
            <span class="opened-label">Pn.-Pt.</span>
            {{item.opened.week || $t('closed', 'Nieczynne') }}
          </div>

          <div class="opened">
            <span class="opened-label">Sb.</span>
            {{ item.opened.sat || $t('closed', 'Nieczynne') }}
          </div>

          <div class="opened">
            <span class="opened-label">Nd.</span>
            {{item.opened.sun || $t('closed', 'Nieczynne') }}
          </div>
        </div>
      </div>

      <hr>

      <div v-if="!isApp" class="row">
        <div class="col-12">
          <p class="line font_bold mb-3">{{ $t('modules.SelenaMap.details') }}</p>

          <div class="line">
            {{ getComposedStreet(item.streetType) }} {{ item.street }}
          </div>

          <div class="line">
            {{ $t('modules.SelenaMap.phone') }}
            <a v-for="phone in item.phones" :href="'tel:'+phone" class="notification"
               :key="phone">{{ phone }}</a>
          </div>

          <div class="line">
            <a :href="'mailto:'+item.email" class="notification">{{ item.email }}</a>
          </div>
        </div>
      </div>

      <hr v-if="!isApp">

      <div class="row">
        <div class="col-12">
          <div class="line font_bold mb-3">
            {{ $t('modules.SelenaMap.services') }}
          </div>

          <div class="services-wrapper">
            <Label v-for="(label, i) in labels" :key="i" :title="label" />
          </div>
        </div>
      </div>

      <hr>

      <div class="row">
        <div class="col-12">
          <div class="d-flex justify-content-center mb-4" v-if="isAvailableMakeAnAppointment">
            <b-btn primary @click="onClick" :disabled="isAvailableMakeAnAppointment">
              {{ $t('modules.SelenaMap.makeAppointment') }}
            </b-btn>
          </div>

          <div class="d-flex justify-content-center">
            <BBtn primary>
              <router-link :to="{ name: `poi.${RouteNames.Single}`, params: { slug: item.slug} }">
                {{ $t('modules.SelenaMap.goToPage') }}
              </router-link>
            </BBtn>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BBtn from '../shared/b-btn'
import Label from './label'
import { getComposedStreet } from '../helpers/street-type-composer'
import { RouteNames } from '../../../routes'

export default {
  name: 'DetailsModal',
  components: {
    BBtn, Label
  },
  data () {
    return {
      RouteNames,
      getComposedStreet: getComposedStreet.bind(this)
    }
  },
  props: {
    item: {
      required: false,
      default: null,
      validator: (v) => typeof v === 'object'
    },
    show: {
      type: Boolean,
      required: true
    },
    isMakeAnAppointment: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  computed: {
    isAvailableMakeAnAppointment () {
      return 0
    },
    isServer () {
      return typeof window === 'undefined'
    },
    isApp () {
      if (!this.isServer) {
        return window.innerWidth < 768
      }
      return false
    },
    labels () {
      if (!this.item.services) {
        return ''
      }
      return this.item.services.map((service) => {
        return service.name
      })
    }
  },
  methods: {
    onClick () {
      this.$emit('click:lead')
    },
    onBackdropClick () {
      this.$emit('update:show', false)
    },
    onModalClick (e) {
      e.stopPropagation()
    }
  }
}
</script>
