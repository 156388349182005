<template>
  <div class="form-group">
    <input class="form-control scrollFix"
           :class="{ 'is-invalid': errors.length }"
           :name="name"
           :placeholder="placeholder"
           :type="type"
           v-model="value" />

    <div v-for="(error, i) in errors" :key="i"
         class="pl-3 invalid-feedback">
      {{ error }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'FakeInput',
  props: {
    errors: {
      type: Array,
      required: false,
      default: () => []
    },
    model: {
      required: true
    },
    name: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: false,
      default: 'text'
    }
  },
  computed: {
    value: {
      get () {
        return this.model
      },
      set (val) {
        this.$emit('update:model', val)
      }
    }
  }
}
</script>
