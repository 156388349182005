<template>
  <div class="modal-content">
    <div class="modal-header d-flex flex-column">
      <h2 class="modal-title border-spaced-bottom align-self-center">DZIĘKUJEMY ZA REJESTRACJĘ WIZYTY</h2>
      <h5 class="align-self-center text-center mb-3">Niebawem warsztat skontaktuje się z Tobą, w celu omówienia szczegółów rezerwacji. <span class="d-block text-center">Potwierdzenie Twojej rezerwacji otrzymasz poprzez SMS.</span></h5>
      <div class="w-100 d-flex flex-column flex-lg-row justify-content-between">
        <div class="label-big">
          <div class="pre font-color-light">
            Warsztat:
          </div>
          <div class="content">
            <div>{{ item.name }}</div>
            <div>{{ item.city }}, {{ getComposedStreet(item.street_type) }} {{ item.street }}</div>
            <div>{{ item.phones.join(' & ') }}</div>
          </div>
        </div>
        <div>
          <div class="label-big">
            <div class="pre font-color-light">
              Data:
            </div>
            <div class="content">
              <div>{{ date }}</div>
            </div>
          </div>
          <div class="label-big">
            <div class="pre font-color-light">
              Godzina:
            </div>
            <div class="content">
              <div>{{ hour }}</div>
            </div>
          </div>
        </div>
      </div>
      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">
          &times;
        </span>
      </button>
    </div>
    <div class="modal-body text-center">
      <div class="box font-size-big">
        {{ description }}
      </div>
    </div>
  </div>
</template>

<script>
import { getComposedStreet } from '../helpers/street-type-composer'

export default {
  name: 'ContentThanks',
  props: {
    date: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    },
    item: {
      type: Object,
      required: true
    },
    hour: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      getComposedStreet: getComposedStreet.bind(this)
    }
  },
}
</script>
