/**
 * Helper class for storing degrees.
 *
 * @param {number} value Degrees.
 *
 * @constructor
 */
export class Degree {
  constructor (value) {
    this.value = value
  }

  /**
   * Converts stored degrees to radians.
   *
   * @returns {number}  Value in radians.
   */
  toRadians () {
    return (this.value * Math.PI) / 180
  }
}

/**
 * Class for storing map point.
 *
 * @param {number} lat Latitude.
 * @param {number} lng Longitude.
 *
 * @constructor
 */
export class Point {
  constructor (lat, lng) {
    this._lat = new Degree(lat)
    this._lng = new Degree(lng)
  }

  /**
   * Returns latitude and longitude as array.
   *
   * @returns  {Array.<number>}  Array [latitude, longitude]
   */
  toArray () {
    return [
      this.lat,
      this.lng
    ]
  }

  /**
   * Get instance of Degree for latitude.
   *
   * @return  {Degree}
   */
  get x () {
    return this._lat
  }

  /**
   * Get latitude.
   *
   * @return  {number}
   */
  get lat () {
    return this._lat.value
  }

  /**
   * Set new value for latitude Degree instance.
   *
   * @return  {void}
   */
  set lat (val) {
    this._lat.value = val
  }

  /**
   * Get longitude.
   *
   * @return  {number}
   */
  get lng () {
    return this._lng.value
  }

  /**
   * Set new value for longitude Degree instance.
   *
   * @return  {void}
   */
  set lng (val) {
    this._lng.value = val
  }

  /**
   * Get instance of Degree for longitude.
   *
   * @return  {Degree}
   */
  get y () {
    return this._lng
  }
}
