<template>
  <div id="map-wrapper" :class="{ 'is-loading': isLoading }">
    <div ref="canvas" id="map-canvas" class="map-canvas" />
    <div id="loader">
      <Loader color="#f5ad23" />
    </div>
  </div>

</template>

<style>
.map-canvas {
  border-left: 1px solid #F8D052;
}
</style>

<script>
import { loadLeaflet } from '../services/leaflet-service'
import Loader from '../shared/loader'
import IconService from '../services/icon-service'

export default {
  name: 'MapCanvas',
  components: {
    Loader
  },
  props: {
    height: {
      type: Number,
      required: true
    },
    lat: {
      type: Number,
      required: true
    },
    loading: {
      type: Boolean,
      required: false,
      default: null
    },
    lng: {
      type: Number,
      required: true
    },
    pins: {
      type: Array,
      required: false,
      default: () => []
    },
    selectedPin: {
      required: false,
      default: null,
      validator: (v) => Array.isArray(v) || v === null
    },
    zoom: {
      type: Number,
      required: true
    },
    eventBus: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      icon: null,
      knownPins: {},
      map: null,
      pinInterval: null,
      Leaflet: null
    }
  },
  computed: {
    isLoading () {
      return this.loading
    }
  },
  mounted () {
    this.$refs.canvas.style.height = `${this.height}px`
    this.loadMap()
  },
  watch: {
    height (val) {
      this.$refs.canvas.style.height = `${val}px`
    },
    map () {
      this.handlePinsChange(this.pins)
    },
    pins: {
      immediate: true,
      handler (val, oldVal) {
        this.handlePinsChange(val, oldVal)
      }
    },
    selectedPin (val) {
      if (typeof val === 'undefined') {
        return
      }
      clearInterval(this.pinInterval)
      const label = document.querySelector('#pin-label')
      label.style.transform = null

      Object.keys(this.knownPins).forEach((key) => {
        if (this.knownPins[key]._icon) {
          this.knownPins[key]._icon.src = this.iconService('pin-regular')
          this.knownPins[key]._icon.classList.remove('is-selected-pin')
        }
      })

      if (val !== null) {
        this.selectPin(...val)
      }
    }
  },
  methods: {
    async loadMap () {
      this.Leaflet = await loadLeaflet()

      if (this.map !== null) {
        return
      }

      this.map = this.Leaflet.map('map-canvas', {
        zoomControl: false
      }).setView([
        this.lat,
        this.lng
      ], this.zoom)

      this.icon = this.Leaflet.icon({
        iconUrl: this.iconService('pin-regular'),
        iconSize: [38, 52],
        iconAnchor: [19, 52]
      })

      this.Leaflet.tileLayer(
        'https://{s}.basemaps.cartocdn.com/rastertiles/voyager_labels_under/{z}/{x}/{y}{r}.png',
        {
          attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>',
          subdomains: 'abcd',
          maxZoom: 19
        }
      ).addTo(this.map)

      this.map.addEventListener('click', (e) => {
        this.$emit('click:map', [e.latlng.lat, e.latlng.lng])
      })
    },
    iconService (icon) {
      return IconService.getInstance().resolver(icon)
    },
    handlePinsChange (val, oldVal) {
      if (!this.map) {
        return
      }
      if (typeof oldVal === 'undefined') {
        oldVal = []
      }

      const oldPins = oldVal.map((item) => {
        return `${item[0]}:${item[1]}`
      })

      const newPins = val.map((item) => {
        return `${item[0]}:${item[1]}`
      })

      const addDifference = val.filter(x => !oldPins.includes(`${x[0]}:${x[1]}`))
      const toRemoveDifference = oldPins.filter(x => newPins.includes(`${x[0]}:${x[1]}`))

      if (toRemoveDifference.length > 0) {
        toRemoveDifference.forEach((pinKey) => {
          this.unsetPinByKey(pinKey)
        })
      }
      if (addDifference.length === 0) {
        return
      }

      addDifference.forEach((coords) => {
        this.setPin(...coords)
      })

      if (Object.keys(this.knownPins).length && Object.keys(this.knownPins)[0]) {
        const pin = this.knownPins[Object.keys(this.knownPins)[0]]
        if (pin._icon.offsetParent.querySelector('#pin-label') === null) {
          const label = document.createElement('div')
          label.setAttribute('id', 'pin-label')

          pin._icon.offsetParent.appendChild(label)
        }
      }
    },
    refreshView () {
      setTimeout(() => {
        if (this.map) {
          this.map.flyTo([this.lat, this.lng], this.zoom)
        }
      }, 250)
    },
    selectPin (lat, lng, title = 'Title') {
      this.knownPins[`${lat}:${lng}`]._icon.src = this.iconService('pin-regular-alternate')
      this.knownPins[`${lat}:${lng}`]._icon.classList.add('is-selected-pin')

      const label = this.knownPins[`${lat}:${lng}`]._icon.offsetParent.querySelector('#pin-label')

      label.innerHTML = title
      this.eventBus.emit('app:map.select_pin', {
        type: 'common',
        event: 'click',
        payload: {
          title: title
        }
      })
      this.pinInterval = setInterval(() => {
        label.style.transform = this.knownPins[`${lat}:${lng}`]._icon.style.transform
      }, 33)
    },
    setPin (lat, lng) {
      this.knownPins[`${lat}:${lng}`] = this.Leaflet.marker([lat, lng], { icon: this.icon })
        .addTo(this.map)
      this.knownPins[`${lat}:${lng}`]._icon.classList.add('fadein')

      this.knownPins[`${lat}:${lng}`]._icon.addEventListener('click', (e) => {
        this.Leaflet.DomEvent.stopPropagation(e)
        this.$emit('click:pin', [lat, lng])
      })
    },
    unsetPinByKey (latLngKey) {
      this.map.removeLayer(this.knownPins[latLngKey])
      delete this.knownPins[latLngKey]
    },
    unsetPin (lat, lng) {
      this.map.removeLayer(this.knownPins[`${lat}:${lng}`])
      delete this.knownPins[`${lat}:${lng}`]
    }
  }
}
</script>
