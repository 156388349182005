/**
 * Lookup table for error codes.
 *
 * @type {object}
 */
const errorCodes = {
  0: 'NOT_SUPPORTED',
  1: 'PERMISSION_DENIED',
  2: 'POSITION_UNAVAILABLE',
  3: 'TIMEOUT'
}

/**
 * Helper function for converting error codes to messages.
 *
 * @returns {string} Error message.
 */
const getError = (code) => {
  return errorCodes[code]
}

/**
 * Attempts to acquire user's geolocation.
 *
 * @returns  {Promise}  Promise object represents user position.
 */
export default () => {
  return new Promise((resolve, reject) => {
    if (typeof navigator !== 'undefined' && 'geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        (pos) => {
          resolve(pos)
        },
        (err) => {
          // eslint-disable-next-line prefer-promise-reject-errors
          reject({
            type: getError(err.code),
            message: err.message
          })
        }
      )
    } else {
      // eslint-disable-next-line prefer-promise-reject-errors
      reject({
        type: getError(0),
        message: 'Geolocation is not supported in user\'s browser'
      })
    }
  })
}
