/** @const */ export const TILE_SIZE_256 = 256
/** @const */ export const TILE_SIZE_512 = 512

/** @const */ const ZOOM_MAX = 13

/**
 * Helper function for calculating Representative Fraction.
 * The latitude is converted to radians and mapped to the range of [-PI / 2, PI / 2]
 * @see https://en.wikipedia.org/wiki/Scale_(map)#The_equirectangular_projection
 *
 * @param {number} lat Latitude in degrees.
 *
 * @returns  {number}  Latitude in radians.
 */
const latRad = (lat) => {
  const sin = Math.sin(lat * Math.PI / 180)
  const radX2 = Math.log((1 + sin) / (1 - sin)) / 2

  return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2
}

/**
 * Calculate optimal zoom relative to map canvas.
 *
 * @param {number} mapPx Width or height of map canvas.
 * @param {number} worldPx Width or height of map tile.
 * @param {number} fr Representative fraction.
 *
 * @returns  {number}  Zoom level.
 */
const zoom = (mapPx, worldPx, fr) => {
  return Math.floor(Math.log(mapPx / worldPx / fr) / Math.LN2)
}

/**
 * Calculate zoom for both latitude and longitude.
 * @see https://en.wikipedia.org/wiki/Mercator_projection
 *
 * @param {Boundaries} bounds Instance of Boundaries.
 * @param {number} mapWidth Width of the map canvas.
 * @param {number} mapHeight Height of the map canvas.
 * @param {number} tileSize Side length of the map tile [GoogleMaps, OpenstreetMap = 256]
 *
 * @returns  {number}  Optimal zoom for fitting all bounds.
 */
export const calculateZoom = (bounds, mapWidth, mapHeight, tileSize) => {
  const ne = bounds.ne
  const sw = bounds.sw

  const latFr = (latRad(ne.lat) - latRad(sw.lat)) / Math.PI

  const lngDiff = ne.lng - sw.lng
  const lngFr = ((lngDiff < 0) ? (lngDiff + 360) : lngDiff) / 360

  const latZoom = zoom(mapHeight, tileSize, latFr)
  const lngZoom = zoom(mapWidth, tileSize, lngFr)

  return Math.min(latZoom, lngZoom, ZOOM_MAX)
}
