



import { Component, Prop, Vue } from 'vue-property-decorator'

/**
 * @author Piotr Niewczas <piotr.niewczas@movecloser.pl>
 */
@Component<Loader>({
  name: 'Loader',
  mounted () {
    this.$refs.loader.style.setProperty('--loader-color', this.color)
    this.$refs.loader.style.setProperty('--loader-size', `${this.size}px`)
  }
})
export class Loader extends Vue {
  public color!: string
  @Prop({ required: false, type: Number, default: 64 })
  public size!: number

  $refs!: {
    loader: HTMLDivElement
  }
}
export default Loader
