export interface Region {
  code: string
  id: number
  name: string
  // eslint-disable-next-line camelcase
  country_id: number
  country: Country
}

export interface Country {
  id: number
  name: string
  // eslint-disable-next-line camelcase
  name_native: string
  code: string
  // eslint-disable-next-line camelcase
  langs: string[]
  // eslint-disable-next-line camelcase
  sales_enabled: boolean
}

export interface Service {
  id: number
  name: string
  desc: string
  // eslint-disable-next-line camelcase
  country_id: number
}

export interface Item {
  id: string
  name: string
  slug: string
  country: Country
  region: Region
  // eslint-disable-next-line camelcase
  postal_code: string|null
  // eslint-disable-next-line camelcase
  vat_id: string
  city: string
  // eslint-disable-next-line camelcase
  street_type: string
  street: string
  lat: number
  lng: number
  place: unknown
  page: string
  services: Service[]
  email: string
  web: string|null
  // eslint-disable-next-line camelcase
  facebook_page: string|null
  phones: string[]
  opened: unknown
  content: string
  cover: unknown
  logo: unknown
  active: boolean
  // eslint-disable-next-line camelcase
  booking_enabled: boolean
  // eslint-disable-next-line camelcase
  booking_days: {[key: string]: boolean}
  // eslint-disable-next-line camelcase
  time_buffer: unknown
  // eslint-disable-next-line camelcase
  created_at: string
}

export interface PoiMapConfig {
  configurableLocales: Record<string, Record<string, any>>
}

export type Pin = [number, number]

export enum View {
  Map = 'map',
  List = 'list'
}
