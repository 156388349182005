/**
 * Countries coordinates.
 *
 * @type {object}
 */
const countriesCenterPoint = {
  LT: {
    lat: 54.9,
    lng: 23.53,
    zoom: 7
  },
  PL: {
    lat: 52.0947634,
    lng: 19.3880948,
    zoom: 7
  },
  HU: {
    lat: 47.058406,
    lng: 19.453094,
    zoom: 7
  }
}

/**
 * Return actual country coordinates
 *
 * @type {object}
 */
export const getCenterCountryPoint = (code) => {
  return countriesCenterPoint[code]
}
