














































































































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { IEventbus } from '@movecloser/front-core'

import { StructureConfigurable } from '../../../../support/mixins'

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import ensureGeolocationPermission from './helpers/get-user-geolocation'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { Point } from './helpers/point'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import calculateDistance from './helpers/calculate-distance'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import calculateBoundaries from './helpers/calculate-boundaries'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { calculateZoom, TILE_SIZE_256 } from './helpers/calculate-zoom'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { getCenterCountryPoint } from './helpers/default'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import IconService from './services/icon-service'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import ApiConnector from './services/api-connector'

import BBtn from './shared/b-btn.vue'
import DetailsModal from './components/details-modal.vue'
import FakeSelect from './shared/fake-select.vue'
import FiltersCanvas from './components/filters-canvas.vue'
import ListCanvas from './components/list-canvas.vue'
import ListItem from './components/list-item.vue'
import List from './components/list.vue'
import MapCanvas from './components/MapCanvas.vue'
import ModalComponent from './components/modal.vue'

import { Item, Pin, PoiMapConfig, Region, Service, View } from './PoiMap.contracts'
import { POI_MAP_CONFIG, POI_MAP_KEY } from './PoiMap.config'

const mobileCheck = function () {
  if (typeof navigator === 'undefined' || typeof window === 'undefined') {
    return false
  }

  let check = false;
  (function (a) {
    /* eslint-disable-next-line */
    if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4))) check = true
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
  })(navigator.userAgent || navigator.vendor || window.opera)
  return check
}

const isMobile = mobileCheck()

@Component<PoiMap>({
  name: 'PoiMap',
  components: {
    BBtn,
    DetailsModal,
    FiltersCanvas,
    FakeSelect,
    ListCanvas,
    ListItem,
    List,
    ModalComponent,
    MapCanvas
  },
  created () {
    this.config = this.getComponentConfig(POI_MAP_KEY, { ...POI_MAP_CONFIG })
  },
  mounted () {
    this.loaded = true
    this.eventBus.handle('resize', () => {
      this.mapHeight = window.innerHeight - (this.isApp ? 300 : 200)
    })

    const connector = ApiConnector.getInstance()

    connector.getRegions(this.countryCode, (data: Region[]) => {
      if (!data) {
        return
      }

      this.regions = data.sort(this.sortRegionsByName)
    })

    connector.getExperts(this.countryCode, (data: Item[]) => {
      this.items = data
    })

    connector.getServices(this.countryCode, (data: Service[]) => {
      this.services = data
    })

    setTimeout(() => {
      this.updateView()
    }, 500)
  }
})
export class PoiMap extends StructureConfigurable {
  @Prop({ required: true, type: Object })
  public readonly eventBus!: IEventbus

  @Prop({ required: true, type: Boolean })
  public readonly showMakeAnAppointment!: boolean

  @Prop({ required: true, type: Boolean })
  public readonly showFilters!: boolean

  @Prop({ required: true, type: String })
  public readonly countryCode!: string

  protected config!: PoiMapConfig

  public loaded = false

  public acquiringGeolocation = false

  public detailsModalShown = false

  public filtersShown = false

  public get configurableLocales (): Record<string, Record<string, any>> {
    return this.getConfigProperty('configurableLocales')
  }

  public get displayRegionConfiguration (): boolean {
    const locale = this.configurableLocales[this.countryCode]
    return locale ? !!locale.displayRegionSelect : true
  }

  public get isMapView (): boolean {
    return this.viewType === View.Map
  }

  public get isListView (): boolean {
    return this.viewType === View.List
  }

  public View = View

  public items: Item[] = []
  public selectedItem: { lat: number; lng: number; name: string }|null = null

  public mapHeight = (!this.isServer) ? (window.innerHeight - (this.isApp ? 300 : 200)) : 300

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  public mapProps: { lat: number; lng: number; zoom: number; [key: string]: any } = {
    lat: 52.0693,
    lng: 19.4803,
    zoom: 6
  }

  public regions: Region[] = []
  public selectedRegion: Region | null = null

  public services: Service[] = []
  public selectedServices: Service[] = []

  public viewType = View.Map

  public get isServer (): boolean {
    return typeof window === 'undefined'
  }

  public get isApp (): boolean {
    if (!this.loaded) {
      return false
    }
    if (!this.isServer) {
      return isMobile
    }
    return false
  }

  public get filteredItems (): Item[] {
    const sortAlphabetically = (ga: Item, gb: Item) => {
      const nameA = ga.city
      const nameB = gb.city

      return nameA.localeCompare(nameB, 'pl')
    }

    if (this.selectedRegion === null && this.selectedServices.length === 0) {
      return this.items.sort(sortAlphabetically)
    }
    const filterRegion = (item: Item) => {
      return item.region.id === this.selectedRegion?.id
    }

    const filterServices = (item: Item) => {
      for (const selectedService of this.selectedServices) {
        const flattenServices = item.services.map((s: Service) => s.id)
        if (!flattenServices.includes(selectedService.id)) {
          return false
        }
      }

      return true
    }

    if (this.selectedRegion === null) {
      return this.items.filter(filterServices).sort(sortAlphabetically)
    }

    return this.items.filter(filterRegion).filter(filterServices).sort(sortAlphabetically)
  }

  public get isFiltersShown () {
    return this.showFilters
  }

  public get isMakeAnAppointment () {
    return this.showMakeAnAppointment
  }

  public get pins (): Pin[] {
    return this.filteredItems.map((item) => {
      return [item.lat, item.lng]
    })
  }

  public get selectedPin () {
    if (this.selectedItem === null) {
      return null
    }

    return [this.selectedItem.lat, this.selectedItem.lng, this.selectedItem.name]
  }

  // public openLeadModal (item: Item) {
  public openLeadModal () {
    // this.eventBus.emit('lead:open', {
    //   item: item || this.selectedItem
    // })
  }

  public icon (icon: string) {
    if (icon == null) {
      icon = 'map-with-pin'
    }
    return IconService.getInstance().resolver(icon)
  }

  public findNearestWorkshop () {
    this.selectedServices = []
    this.selectedRegion = null
    this.acquiringGeolocation = true
    this.viewType = View.Map

    ensureGeolocationPermission()
      .then(({ coords }: { coords: { latitude: number; longitude: number } }) => {
        const userLocation = new Point(coords.latitude, coords.longitude)
        let itemLocation
        let distance: number|null = null
        let minDistance: number|null = null
        let closestItem: Item | null = null

        this.items.forEach((item: Item) => {
          itemLocation = new Point(item.lat, item.lng)
          distance = calculateDistance(userLocation, itemLocation)

          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          if (minDistance === null || distance < minDistance) {
            minDistance = distance
            closestItem = item as Item
            this.selectedItem = closestItem
          }
        })

        if (this.isMapView && closestItem !== null) {
          this.setMap({
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            lat: closestItem.lat,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            lng: closestItem.lng,
            zoom: 9
          })
        }
      })
      .catch((reason: Error) => {
        console.log(reason)
      })
      .finally(() => {
        this.acquiringGeolocation = false
      })
  }

  public onFiltersChange (filters: Service[]) {
    this.selectedServices = filters
  }

  public onItemClick (item: Item) {
    this.selectedItem = item

    setTimeout(() => {
      if (this.isApp) {
        if (this.isMapView) {
          document.querySelector('html')?.classList.add('fix-me')

          this.setMap({
            lat: item.lat,
            lng: item.lng,
            zoom: 9
          })
        }
      }

      this.detailsModalShown = true
    }, 25)
  }

  public onMapClick () {
    this.filtersShown = false
    this.selectedItem = null
  }

  public onPinClick (pin: Pin) {
    this.filtersShown = false
    this.selectedItem = this.items.find((item: {lat: number; lng: number}) => {
      return item.lat === pin[0] && item.lng === pin[1]
    }) || null
  }

  public setMap (payload: any) {
    Object.keys(payload).forEach((key) => {
      this.mapProps[key] = payload[key]
    })

    const mapRef = this.$refs.map as Element
    if (mapRef) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.$refs.map.refreshView()
    }
  }

  public setView (view: View) {
    this.viewType = view
  }

  public toggleAppView (item: Item) {
    if (!this.isApp) {
      return
    }

    this.viewType = View.Map

    setTimeout(() => {
      this.onItemClick(item)
    }, 250)
  }

  public updateView () {
    this.selectedItem = null

    const bounds = calculateBoundaries(this.filteredItems.map((item: Item) => {
      return new Point(item.lat, item.lng)
    }))

    const mapRef = this.$refs.map as Vue
    const canvasRef = mapRef.$refs.canvas as HTMLCanvasElement

    if (!this.isServer) {
      if (bounds.valid) {
        this.setMap({
          lat: bounds.center.lat,
          lng: bounds.center.lng,
          zoom: calculateZoom(
            bounds,
            canvasRef ? canvasRef.getBoundingClientRect().width : 1024,
            canvasRef ? canvasRef.getBoundingClientRect().height : 1024,
            TILE_SIZE_256
          )
        })
      } else {
        this.setMap({ ...getCenterCountryPoint(this.countryCode), zoom: 6 })
      }
    }
  }

  private sortRegionsByName (region1: Region, region2: Region) {
    return region1.name.localeCompare(region2.name, 'pl')
  }

  @Watch('selectedRegion')
  onChangedSelectedRegion () {
    this.selectedServices = []
    this.selectedItem = null

    if (this.isMapView) {
      this.updateView()
    }
  }

  @Watch('selectedItem')
  onSelectedItem (item: Item) {
    if (item && this.isApp) {
      this.$nextTick(() => {
        if (!this.$refs.slider) return

        const slider = this.$refs.slider as HTMLDivElement
        const sliderActiveElement = slider.querySelector('.is-active') as HTMLDivElement
        slider.scroll({
          left: sliderActiveElement.offsetLeft - 15,
          behavior: 'smooth'
        })
      })
    }
  }

  @Watch('detailsModalShown')
  onDetailsModalShown (val: boolean) {
    if (!val) {
      document.querySelector('html')?.classList.remove('fix-me')
    }
  }
}

export default PoiMap
