import { Point } from './point'

/**
 * Class for properly orienting latitude and longitude on map.
 * @param {number} latMin Lowest point's latitude.
 * @param {number} latMax Highest point's latitude.
 * @param {number} lngMin Lowest point's longitude.
 * @param {number} lngMax Highest point's longitude.
 * @constructor
 */
export default class Boundaries {
  constructor (latMin, latMax, lngMin, lngMax) {
    this.latMin = latMin
    this.latMax = latMax
    this.lngMin = lngMin
    this.lngMax = lngMax
  }

  /**
   * Describes if Boundaries are equivalent to point.
   *
   * @returns {boolean}
   */
  get arePoint () {
    return this.valid &&
      this.latMin === this.latMax &&
      this.lngMin === this.lngMax
  }

  /**
   * Returns Point representing the boundaries center.
   *
   * @returns  {Point}
   */
  get center () {
    if (!this.valid) {
      throw new TypeError('Given boundaries are not valid. Use "valid" getter before invoking "center"')
    }

    if (this.arePoint) {
      return this.sw
    }

    return new Point(
      (this.nw.lat + this.se.lat) / 2,
      (this.nw.lng + this.se.lng) / 2
    )
  }

  /**
   * Describes if boundaries can be presented on map.
   *
   * @returns  {boolean}
   */
  get valid () {
    return this.latMin > -1 &&
      this.latMax > -1 &&
      this.lngMin > -1 &&
      this.lngMax > -1
  }

  /**
   * Combines all boundaries in object.
   *
   * @returns  {object}  nw, se, sw and se points.
   */
  get all () {
    return {
      nw: this.nw,
      ne: this.ne,
      sw: this.sw,
      se: this.se
    }
  }

  /**
   * The north western Point.
   *
   * @returns  {Point}
   */
  get nw () {
    return new Point(this.latMax, this.lngMin)
  }

  /**
   * Alias for nw.
   *
   * @returns  {this.nw}
   */
  get getNorthWest () {
    return this.nw
  }

  /**
   * The north eastern Point.
   *
   * @returns  {Point}
   */
  get ne () {
    return new Point(this.latMax, this.lngMax)
  }

  /**
   * Alias for ne.
   *
   * @returns  {this.ne}
   */
  get getNorthEast () {
    return this.ne
  }

  /**
   * The south western Point.
   *
   * @returns  {Point}
   */
  get sw () {
    return new Point(this.latMin, this.lngMin)
  }

  /**
   * Alias for sw.
   *
   * @returns  {this.sw}
   */
  get getSouthWest () {
    return this.sw
  }

  /**
   * The south eastern Point.
   *
   * @returns  {Point}
   */
  get se () {
    return new Point(this.latMin, this.lngMax)
  }

  /**
   * Alias for se.
   *
   * @returns  {this.se}
   */
  get getSouthEast () {
    return this.se
  }
}
