// import { translate } from '../../../util/translator';

const SHORTCODE = {
  // 'st': translate('street-shortcut' , 'ul.'),
  st: 'ul.',
  av: 'al.'
}

const FULL_NAME = {
  st: 'Ulica',
  av: 'Aleja'
}

export function getComposedStreet (key, shortcode = true) {
  return shortcode ? SHORTCODE[key] : FULL_NAME[key]
}
