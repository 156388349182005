<template>
  <div id="filters-wrap" :class="{ isExpanded: showFilters }">
    <div class="filters-block">
      <div class="title">
        {{$t('modules.SelenaMap.chooseFilters')}}
      </div>

      <div class="row m-0">
        <div class="col-12 col-lg-6">
          <div class="row">
            <div v-for="s in colA" :key="s.id" class="option col-12 p-0">

              <b-checkbox
                :id="`option-${s.id}`"
                :label="s.name"
                :value="s.id"
                :model.sync="selected"
              />
            </div>
          </div>
        </div>

        <div class="col-12 col-lg-6">
          <div class="row">
            <div v-for="s in colB" :key="s.id" class="option col-12 p-0">
              <b-checkbox
                :id="`option-${s.id}`"
                :label="s.name"
                :value="s.id"
                :model.sync="selected"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="filters-handle d-flex flex-column flex-wrap justify-content-center" @click="toggle">
      <b-btn class="bordered">
        <div class="d-flex flex-row">
          <div class="ico">
            <img :src="this.icon('caret-down')" />
          </div>
          <div class="text">{{$t('modules.SelenaMap.filter')}}</div>
        </div>
      </b-btn>
    </div>
  </div>
</template>

<script>
import BBtn from '../shared/b-btn'
import BCheckbox from '../shared/b-checkbox'
import IconService from '../services/icon-service'

export default {
  name: 'FiltersCanvas',
  components: {
    BBtn, BCheckbox
  },
  props: {
    services: {
      type: Array,
      required: true
    },
    show: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      selected: [],
      showFilters: false
    }
  },
  computed: {
    isServer () {
      return typeof window === 'undefined'
    },
    isApp () {
      if (!this.isServer) {
        return window.innerWidth < 768
      }
      return false
    },
    colA () {
      return this.services.slice(0, Math.ceil(this.services.length / 2))
    },
    colB () {
      return this.services.slice(Math.ceil(this.services.length / 2))
    }
  },
  watch: {
    selected (val) {
      this.$emit('change', val)
    }
  },
  methods: {
    icon (icon) {
      return IconService.getInstance().resolver(icon)
    },
    toggle (e) {
      e.stopPropagation()
      this.showFilters = !this.showFilters
      this.$emit('update:show', this.showFilters)
    }
  }
}
</script>
