<template>
  <div
    ref="item"
    class="list-item"
    :class="{ 'list-item-alternate': alternate, 'list-item-predefined': item !== null,
    'is-invisible': !visible, 'is-active': selected, 'list-item-standalone': standalone,
    'is-app': isApp }"
    v-on="$listeners"
  >
    <slot v-if="!item" />

    <template v-else>
      <div class="item-section">
        <div class="item-city">
          {{ item.city }}
        </div>

        <router-link :to="{ name: `poi.${RouteNames.Single}`, params: { slug: item.slug} }" class="item-name">
          {{ item.name }}
        </router-link>
      </div>

      <div class="item-section">
        <div class="item-address">
          {{ getComposedStreet(item.street_type) }} {{ item.street }}, {{ item.postal_code }}
        </div>

        <div class="item-phones">
          {{ $t('modules.SelenaMap.phone') }} {{ item.phones.join(' & ') }}
        </div>
      </div>

      <div ref="labelsWrap" class="item-section d-flex flex-wrap flex-grow-1">
        <Label ref="labels" v-for="(label, i) in labels" :key="i" :title="label + ''"/>
      </div>
    </template>

    <template v-if="standalone && !isApp && isAvailableMakeAnAppointment && isMakeAnAppointment">
      <div class="item-cta">
        <a href="#" @click="$emit('click:cta')">
          {{$t('modules.SelenaMap.makeAppointment')}}
        </a>
      </div>
    </template>

    <div class="d-flex justify-content-center mt-4" v-if="!isMap">
      <BBtn primary>
        <router-link :to="{ name: `poi.${RouteNames.Single}`, params: { slug: item.slug} }">
          {{ $t('modules.SelenaMap.goToPage') }}
        </router-link>
      </BBtn>
    </div>
  </div>
</template>

<script>
import Label from './label'
import { getComposedStreet } from '../helpers/street-type-composer'
import BBtn from '../shared/b-btn.vue'
import { RouteNames } from '../../../routes'

export default {
  name: 'ListItem',
  components: {
    Label,
    BBtn
  },
  props: {
    alternate: {
      type: Boolean,
      required: false,
      default: false
    },
    item: {
      type: Object,
      required: false,
      default: null
    },
    labelsRows: {
      type: Number,
      required: false,
      default: 2
    },
    isMap: {
      type: Boolean,
      required: true,
      default: false
    },
    selected: {
      type: Boolean,
      required: false,
      default: false
    },
    standalone: {
      type: Boolean,
      required: false,
      default: false
    },
    isMakeAnAppointment: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data () {
    return {
      RouteNames,
      height: 0,
      mutatedLabels: [],
      visible: true,
      getComposedStreet: getComposedStreet.bind(this)
    }
  },
  created () {
    if (!this.item) {
      return
    }

    this.mutatedLabels = this.item.services.map((service) => {
      return service.name
    })
  },
  mounted () {
    if (!this.isServer) {
      if (this.mutatedLabels.length) {
        this.$nextTick(() => {
          const maxHeight = (this.$refs.labels[0].$el.getBoundingClientRect().height + 4) * this.labelsRows
          const currentHeight = this.$refs.labelsWrap.getBoundingClientRect().height

          const maxWidth = this.$refs.labelsWrap.getBoundingClientRect().width * this.labelsRows

          if (currentHeight > maxHeight) {
            let width = 0
            let i = 0
            while (width < maxWidth && i < this.$refs.labels.length) {
              width += this.$refs.labels[i].$el.getBoundingClientRect().width + 4
              i++
            }

            this.mutatedLabels = this.mutatedLabels.slice(0, i - 2)
            this.mutatedLabels.push(`+ ${this.item.services.length - (i - 2)}`)
          }

          this.$nextTick(() => {
            this.height = this.$refs.item.getBoundingClientRect().height
          })
        })
      } else {
        this.$nextTick(() => {
          if (this.$refs.item) {
            this.height = this.$refs.item.getBoundingClientRect().height
          }
        })
      }
    }
  },
  computed: {
    isServer () {
      return typeof window === 'undefined'
    },
    isApp () {
      if (!this.isServer) {
        return window.innerWidth < 768
      }
      return false
    },
    labels () {
      return this.mutatedLabels
    },
    isAvailableMakeAnAppointment () {
      return 0 // this.item.bookingEnabled;
    }
  },
  methods: {
    setVisibility (val) {
      this.visible = val
    }
  }
}
</script>
