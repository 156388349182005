<template>
  <div class="custom-control custom-checkbox" :class="{ 'is-regular': regular }">
    <input type="checkbox" class="custom-control-input" :class="{ 'is-invalid': errors.length }" :id="id" :value="value"
           v-model="vModel" v-on="$listeners">
    <label class="custom-control-label" :for="id">
      <div v-if="icon" class="icon">
        <img :src="icon">
      </div>
      <span v-html="label"/>
    </label>
    <div v-for="(error, i) in errors" :key="i" class="invalid-feedback">
      {{ error }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'BCheckbox',
  props: {
    errors: {
      type: Array,
      required: false,
      default: () => []
    },
    icon: {
      type: String,
      required: false,
      default: null
    },
    id: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    model: {
      required: true,
      validator: (v) => Array.isArray(v) || typeof v === 'boolean'
    },
    regular: {
      type: Boolean,
      required: false,
      default: false
    },
    value: {
      required: true
    }
  },
  computed: {
    vModel: {
      get () {
        return this.model
      },
      set (val) {
        this.$emit('update:model', val)
      }
    }
  }
}
</script>
