<template>
  <div class="modal-content">
    <div class="modal-header d-flex flex-column">
      <h2 class="modal-title">Rejestracja wizyty</h2>
      <h5 class="m-0">Możesz zarezerwować wizytę we wskazanych poniżej dniach</h5>
      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">
          &times;
        </span>
      </button>
    </div>
    <div class="modal-body">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12 col-lg-6 p-0 border-offset-right">
            <div class="box">
              <div class="input-wrapper">
                <FakeSelect
                  compact
                  placeholder="województwa"
                  :model.sync="form.region"
                  :options="regions"
                  @change="onRegionChange"
                />
              </div>
              <div class="input-wrapper">
                <FakeSelect
                  compact
                  placeholder="warsztat"
                  :disabled="form.region === null"
                  :model.sync="form.item"
                  :options="filteredItems"
                  @change="onItemChange"
                />
              </div>
              <div class="input-wrapper">
                <FakeSelect
                  compact
                  :placeholder="form.services.length ? form.services.map(id => services.find(s => s.id === id).name).join(', ') : 'wybierz rodzaj usług'"
                  :disabled="form.item === null"
                  :errors="errors.services"
                >
                  <div class="filters-block">
                    <div class="row m-0">
                      <div class="col-12 col-xl-6">
                        <div class="row m-0">
                          <div v-for="s in colA" :key="s.id" class="option col-12 p-0">
                            <b-checkbox
                              :icon="this.iconService(s.icon)"
                              :id="`modal-option-${s.id}`"
                              :label="s.name"
                              :value="s.id"
                              :model.sync="form.services"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-xl-6">
                        <div class="row m-0">
                          <div v-for="s in colB" :key="s.id" class="option col-12 p-0">
                            <b-checkbox
                              :icon="this.iconService(s.icon)"
                              :id="`modal-option-${s.id}`"
                              :label="s.name"
                              :value="s.id"
                              :model.sync="form.services"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </FakeSelect>
              </div>
            </div>
            <div class="box box-bg">
              <div class="input-wrapper">
                <FakeInput
                  placeholder="imię i nazwisko"
                  name="name"
                  :model.sync="form.name"
                  :errors="errors.name"
                />
              </div>
              <div class="input-wrapper">
                <FakeInput
                  placeholder="numer rejestracyjny pojazdu"
                  name="vehicle_no"
                  :model.sync="form.vehicle"
                  :errors="errors.vehicle"
                />
              </div>
              <div class="input-wrapper">
                <FakeInput
                  placeholder="numer kontaktowy"
                  name="phone"
                  type="tel"
                  :model.sync="form.phone"
                  :errors="errors.phone"
                />
              </div>
              <div class="input-wrapper">
                <FakeTextarea
                  placeholder="opis usterki"
                  name="desc"
                  :model.sync="form.desc"
                  :errors="errors.desc"
                />
              </div>
              <div class="input-wrapper">
                <b-checkbox
                  regular
                  id="privacy-policy-checkbox"
                  class="mt-4 mb-0"
                  :label="`Akceptuję <a href='${ privacyPath }' target='_blank'>politykę prywatności</a> i <a href='${ termsPath }' target='_blank'>regulamin</a> strony.`"
                  :value="true"
                  :model.sync="form.privacyPolicy"
                  :errors="errors.privacyPolicy"
                />
              </div>
              <div class="input-wrapper">
                <b-checkbox
                  regular
                  id="marketing-agreement-checkbox"
                  class="mt-4 mb-0"
                  label="<span>Chcę otrzymywać oferty handlowe od Continental Opony Polska Sp. z o.o.</span>"
                  :value="true"
                  :model.sync="form.marketingAgreement"
                  :errors="errors.marketingAgreement"
                />
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-6 p-0 d-flex flex-column">
            <div class="box">
              // DatePicker
<!--              <DatePicker-->
<!--                :disabled="form.item === null"-->
<!--                :item="form.item"-->
<!--                :model.sync="form.date"-->
<!--              />-->
            </div>
            <div class="box box-sm flex-fill d-flex align-items-center justify-content-center">
              <span class="font-weight-bold mr-3">WYBIERZ GODZINĘ:</span>
              <FakeSelect
                compact
                centered
                class="flex-grow-1 is-fill"
                :disabled="form.date === null"
                :placeholder="hours[0] ? hours[0].name : '08:00'"
                :model.sync="form.time"
                :options="hours"
                :errors="errors.time"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer d-flex justify-content-center">
      <b-btn
        primary
        :disabled="request || isButtonDisabled"
        :loading="request"
        @click="onButtonClick"
      >
        ZAREZERWUJ WIZYTĘ
      </b-btn>
    </div>
  </div>
</template>

<script>
// import moment from 'moment'

import BBtn from '../shared/b-btn'
import BCheckbox from '../shared/b-checkbox'
// import DatePicker from './date-picker'
import FakeInput from '../shared/fake-input'
import FakeSelect from '../shared/fake-select'
import FakeTextarea from '../shared/fake-textarea'
import IconService from '../services/icon-service'
import ApiConnector from '../services/api-connector'

export default {
  name: 'ContentReservation',
  components: {
    BBtn, BCheckbox, FakeInput, FakeSelect, FakeTextarea
  },
  props: {
    feed: {
      required: true,
      validator: (v) => typeof v === 'object'
    },
    countryCode: {
      required: true,
      type: String
    }
  },
  data () {
    return {
      errors: {
        desc: [],
        marketingAgreement: [],
        name: [],
        phone: [],
        privacyPolicy: [],
        services: [],
        time: [],
        vehicle: []
      },
      form: {
        date: null,
        desc: '',
        item: null,
        marketingAgreement: false,
        name: null,
        phone: null,
        privacyPolicy: false,
        region: null,
        services: [],
        time: null,
        vehicle: null
      },
      items: [],
      regions: [],
      privacyPath: window.privacyPath,
      termsPath: window.termsPath,
      request: false,
      services: []
    }
  },
  mounted () {
    this.api.getRegions(this.countryCode, (data) => {
      this.regions = data.sort((region1, region2) => region1.name.localeCompare(
        region2.name,
        'pl'
      ))
    })

    this.api.getExperts(this.countryCode, (data) => {
      this.items = data.sort((item1, item2) => item1.city.localeCompare(
        item2.city,
        'pl'
      ))
    })

    this.api.getServices(this.countryCode, (data) => {
      this.services = data
    })
  },
  watch: {
    feed (feed) {
      const item = feed.item
      const region = feed.region
      const service = feed.service

      if (region) {
        this.form.region = region
      }

      if (item) {
        if (typeof item === 'object') {
          this.form.region = item.region
          this.form.item = item
        } else {
          this.api.getItem(item, this.countryCode, (data) => {
            this.form.region = data.region
            this.form.item = data
          })
        }
      }

      if (service) {
        this.form.services.push(service)
      }
    }
  },
  computed: {
    api () {
      return ApiConnector.getInstance()
    },
    colA () {
      return this.filteredServices.slice(0, Math.ceil(this.filteredServices.length / 2))
    },
    colB () {
      return this.filteredServices.slice(Math.ceil(this.filteredServices.length / 2))
    },
    filteredItems () {
      if (this.form.region) {
        return this.items.filter((item) => {
          return item.region.id === this.form.region.id && item.booking_enabled
        })
      }

      return []
    },
    filteredServices () {
      if (this.form.item) {
        return this.services.filter((service) => {
          return this.form.item.services.map(s => s.id).indexOf(service.id) > -1
        })
      }

      return []
    },
    hours () {
      const hs = []
      if (this.form.item === null || this.form.date === null) {
        return []
      }

      // const m = moment(this.form.date)
      const m = this.form.date
      const mFormatted = m.format('YYYY-MM-DD')
      const day = m.isoWeekday()
      const key = day === 7 ? 'sun' : (day === 6 ? 'sat' : 'week')
      const hrs = this.form.item.opened[key].split(' - ').map((hr) => {
        return hr.split(':')
      })

      const now = m.clone().set({ hour: hrs[0][0], minutes: hrs[0][1] })
      const max = m.clone().set({ hour: hrs[1][0], minutes: hrs[1][1] }).unix()

      const leads = this.form.item.leads[mFormatted] || []

      while (now.unix() < max) {
        if (!leads.includes(now.format('HH:mm:ss'))) {
          hs.push({
            id: now.unix(),
            name: now.format('HH:mm')
          })
        }

        now.add(30, 'minutes')
      }

      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.form.time = hs[0]

      return hs
    },
    isButtonDisabled () {
      return false// Array.from(Object.values(this.form)).indexOf(null) > -1 || this.form.services.length === 0
    }
  },
  methods: {
    iconService (icon) {
      return IconService.getInstance().resolver(icon)
    },
    clearErrors () {
      for (const key in this.errors) {
        this.errors[key] = []
      }
    },
    onButtonClick () {
      if (!this.isButtonDisabled) {
        this.clearErrors()
        if (this.form.privacyPolicy === false) {
          this.errors.privacyPolicy.push(
            'Złożenie rezerwacji możliwe jest dopiero po zaakceptowaniu regulaminu.'
          )
          return
        }

        if (this.form.name && !this.form.name.includes(' ')) {
          this.errors.name.push('Imię i nazwisko jest wymagane.')
          return
        }

        this.request = true

        const [first, last] = (this.form.name || ' ').split(' ').map(s => s.trim())

        this.api.createReservation((this.form.item || { id: 1 }).id, {
          first: first,
          last: last,
          phone: this.form.phone,
          vehicle_no: this.form.vehicle,
          desc: this.form.desc,
          services: this.form.services,
          when: this.form.date === null || this.form.time === null ? null
            : `${this.form.date} ${this.form.time.name}:00`,
          marketing_agreement: this.form.marketingAgreement
        }, (data) => {
          this.request = false
          if (data && data.failed) {
            return this.setErrors(data.errors)
          }

          this.$emit('created', this.form)
        })
      }
    },
    onItemChange () {
      this.form.services = []
      this.form.date = null
      this.form.time = null
    },
    onRegionChange () {
      this.form.item = null
      this.form.services = []
      this.form.date = null
      this.form.time = null
    },
    setErrors (errors) {
      for (const key in errors) {
        const localKey = this.transpileKey(key)

        this.errors[localKey] = [...(this.errors[localKey] || []), ...errors[key]]
      }
    },
    transpileKey (key) {
      switch (key) {
        case 'first':
          return 'name'
        case 'last':
          return 'name'
        case 'vehicle_no':
          return 'vehicle'
        case 'when':
          return 'time'
        default:
          return key
      }
    }
  }
}
</script>
