import Boundaries from './boundaries'

/**
 * Calculates minimum and maximum for both latitude and longitude of provided Points.
 *
 * @param   {Array.<Point>} points Array of Points.
 *
 * @returns  {Boundaries}
 */
export default (points) => {
  if (points.length === 0) {
    return new Boundaries(-1, -1, -1, -1)
  }

  if (points.length === 1) {
    return new Boundaries(points[0].lat, points[0].lat, points[0].lng, points[0].lng)
  }

  let latMin = null
  let latMax = null
  let lngMin = null
  let lngMax = null

  let lat, lng
  points.forEach((point) => {
    lat = point.lat
    lng = point.lng

    if (latMin === null || lat < latMin) {
      latMin = lat
    }

    if (latMax === null || lat > latMax) {
      latMax = lat
    }

    if (lngMin === null || lng < lngMin) {
      lngMin = lng
    }

    if (lngMax === null || lng > lngMax) {
      lngMax = lng
    }
  })

  return new Boundaries(latMin, latMax, lngMin, lngMax)
}
