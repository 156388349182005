<template>
  <div id="leadModal" class="modal fade" tabindex="-1" role="dialog"
       v-if="showMakeAnAppointment === '1'">
    <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
      <transition name="fade">
        <ContentReservation
          v-if="view === 0"
          :feed="feed"
          :countryCode="countryCode"
          @created="onReservationCreated"
        />
      </transition>
      <transition name="fade">
        <ContentThanks
          v-if="view === 1"
          :date="reservation.date"
          :description="reservation.description"
          :item="reservation.item"
          :hour="reservation.hour"
        />
      </transition>
    </div>
  </div>
</template>

<script>
// import moment from 'moment'

import ContentReservation from './content-reservation'
import ContentThanks from './content-thanks'

export default {
  name: 'LeadModal',
  components: {
    ContentReservation, ContentThanks
  },
  props: {
    eventBus: {
      required: true,
      type: Object
    },
    showMakeAnAppointment: {
      required: true,
      type: String
    },
    countryCode: {
      required: true,
      type: String
    }
  },
  data () {
    return {
      feed: null,
      reservation: {
        date: null,
        description: null,
        item: null,
        hour: null
      },
      view: 0
    }
  },
  mounted () {
    this.eventBus.handle('lead:open', (data) => {
      this.view = 0

      document.querySelector('html').classList.add('fix-me')

      if (data && typeof data === 'object') {
        this.feed = data
      } else {
        this.feed = null
      }

      console.log('should open modal! missing jquery.')
      // $('#leadModal').modal()
      //
      // const cb = () => {
      //   document.querySelector('html').classList.remove('fix-me')
      //
      //   $('#leadModal').off('hide.bs.modal', cb)
      // }
      // $('#leadModal').on('hide.bs.modal', cb)
    })
  },
  methods: {
    onReservationCreated (formData) {
      // this.reservation.date = moment(formData.date, 'YYYY-MM-DD').format('DD-MM-YYYY')
      this.reservation.date = formData.date
      this.reservation.description = formData.desc
      this.reservation.item = formData.item
      this.reservation.hour = formData.time.name

      this.view = 1
    }
  }
}
</script>
