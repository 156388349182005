










import { Component } from 'vue-property-decorator'

import { EventbusType, IEventbus } from '@movecloser/front-core'

import { ISiteService, SiteServiceType } from '../../../contexts'
import { StructureConfigurable } from '../../../support/mixins'
import { Inject } from '../../../support'
import { PoiMap as PoiMapComponent } from '../shared/map/PoiMap.vue'

@Component<PoiMap>({
  name: 'PoiMap',
  components: { PoiMapComponent }
})
export class PoiMap extends StructureConfigurable {
  @Inject(EventbusType)
  protected readonly eventBus!: IEventbus

  @Inject(SiteServiceType)
  public readonly siteService!: ISiteService

  public get activeLocale (): string {
    return this.siteService.getActiveSite().locale
  }
}

export default PoiMap
