

























import { Component, Prop, Vue } from 'vue-property-decorator'

import Loader from './loader.vue'

/**
 * @author Piotr Niewczas <piotr.niewczas@movecloser.pl>
 */
@Component<BBtn>({
  name: 'BBtn',
  components: { Loader }
})
export class BBtn extends Vue {
  @Prop({ required: false, type: Boolean, default: false })
  public active!: boolean

  @Prop({ required: false, type: Boolean, default: false })
  public black!: boolean

  @Prop({ required: false, type: Boolean, default: false })
  public block!: boolean

  @Prop({ required: false, type: Boolean, default: false })
  public iconOffMobile!: boolean

  @Prop({ required: false, type: Boolean, default: false })
  public iconOnly!: boolean

  @Prop({ required: false, type: String, default: null })
  public icon!: string

  @Prop({ required: false, type: Boolean, default: false })
  public light!: boolean

  @Prop({ required: false, type: Boolean, default: false })
  public loading!: boolean

  @Prop({ required: false, type: Boolean, default: false })
  public primary!: boolean
}

export default BBtn
