<template>
  <div ref="wrapper" id="list-wrapper" :class="{ 'is-app': this.isApp }">
    <div class="row">
      <div v-if="!this.isApp" class="list-overlay" :class="{ 'd-block': selectedItem !== null }"
           @click="$emit('click:backdrop')"/>
      <div v-if="this.isApp" class="scroller-helper">
        <a href="#" v-for="(id, key) in letters" :key="key" @click.prevent="scrollTo(id)">
          {{key}}
        </a>
      </div>

      <div class="col-12 d-flex align-items-center mb-3">
        <span class="font-color-light text-uppercase">
          {{$t('modules.SelenaMap.availableItems') }}
        </span>

        <span class="items-count px-2">{{ items.length }}</span>
      </div>

      <div v-for="(item, i) in items" :key="i" class="col-12 col-md-6 col-lg-4 col-xl-3 mb-3">
        <ListItem
          :ref="`listitem-${item.id}`"
          standalone
          :isMakeAnAppointment="isMakeAnAppointment"
          :selected="isSelected(item)"
          :item="item"
          :labels-rows="3"
          :isMap="isMap"
          @click="$emit('click:item', item)"
          @click:cta="$emit('click:lead', item)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ListItem from './list-item'

export default {
  name: 'ListCanvas',
  components: {
    ListItem
  },
  props: {
    items: {
      type: Array,
      required: true
    },
    selectedItem: {
      required: true,
      validator: v => typeof v === 'object'
    },
    isMap: {
      required: true,
      type: Boolean,
      default: false
    },
    isMakeAnAppointment: {
      required: false,
      type: Boolean,
      default: true
    }
  },
  computed: {
    isServer () {
      return typeof window === 'undefined'
    },
    isApp () {
      if (!this.isServer) {
        return window.innerWidth < 768
      }
      return false
    },
    letters () {
      const obj = {}
      this.items.forEach((item) => {
        const fl = item.city.charAt(0)

        if (typeof obj[fl] === 'undefined') {
          obj[fl] = item.id
        }
      })

      return obj
    }
  },
  methods: {
    isSelected (item) {
      const res = this.selectedItem && this.selectedItem.id === item.id

      if (res) {
        const fn = () => {
          this.$refs.wrapper.scrollTop = 0

          this.$nextTick(() => {
            if (!this.isServer) {
              this.$refs.wrapper.scrollTop = this.$refs[`listitem-${item.id}`][0].$el.getBoundingClientRect().top - this.$refs.wrapper.getBoundingClientRect().top - 15
            }
          })
        }

        if (this.$refs.wrapper) {
          fn()
        } else {
          setTimeout(fn, 500)
        }
      }

      return res
    },
    scrollTo (id) {
      if (!this.isServer) {
        this.$refs.wrapper.scroll({
          top: this.$refs[`listitem-${id}`][0].$el.getBoundingClientRect().top,
          left: 0,
          behavior: 'smooth'
        })
      }
    }
  }
}
</script>
