<template>
  <div class="form-group">
    <textarea class="form-control scrollFix" :class="{ 'is-invalid': errors.length }" :name="name" :placeholder="placeholder" v-model="value" rows="2" />
    <div v-for="(error, i) in errors" :key="i" class="pl-3 invalid-feedback">
      {{ error }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'FakeTextarea',
  props: {
    errors: {
      type: Array,
      required: false,
      default: () => []
    },
    model: {
      required: true
    },
    name: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      required: true
    },
  },
  computed: {
    value: {
      get () {
        return this.model
      },
      set (val) {
        this.$emit('update:model', val)
      }
    }
  }
}
</script>
