<template>
  <span class="label mr-1 mt-1">{{ title }}</span>
</template>

<script>
export default {
  name: 'Label',
  props: {
    title: {
      type: String,
      required: true
    }
  }
}
</script>
