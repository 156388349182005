var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',_vm._g({staticClass:"btn",class:{
    'btn-black': _vm.black,
    'btn-icon': _vm.icon,
    'btn-light': _vm.light,
    'btn-primary': _vm.primary,
    'icon-only': _vm.iconOnly,
    'icon-off-mobile': _vm.iconOffMobile,
    'btn-block': _vm.block,
    'active': _vm.active
  },attrs:{"type":"button","disabled":_vm.loading}},_vm.$listeners),[(_vm.icon || _vm.loading)?_c('div',{staticClass:"icon"},[(_vm.loading)?_c('Loader',{attrs:{"size":28}}):_c('img',{attrs:{"src":_vm.icon}})],1):_vm._e(),_c('span',{staticClass:"btn-inner"},[_vm._t("default")],2)])}
var staticRenderFns = []

export { render, staticRenderFns }