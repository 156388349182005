import { Degree } from './point'
/**
 * Calculates distance between given Points.
 *
 * @param  {Point} pointA Instance of Point.
 * @param  {Point} pointB Instance of Point.
 *
 * @returns {number} Distance in meters.
 */
export default (pointA, pointB) => {
  const R = 6371
  const latA = pointA.x.toRadians()
  const latB = pointB.x.toRadians()
  const deltaLat = new Degree(pointB.lat - pointA.lat).toRadians()
  const deltaLng = new Degree(pointB.lng - pointA.lng).toRadians()

  const a = Math.sin(deltaLat / 2) * Math.sin(deltaLat / 2) +
    Math.cos(latA) * Math.cos(latB) *
    Math.sin(deltaLng / 2) * Math.sin(deltaLng / 2)

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))

  return R * c
}
